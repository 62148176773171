<template>
  <div>
    <div>
      <img class="rounded" @click="toggler = !toggler" :src="resources[0]" alt="" style="width:100%; height:300px; background-size: cover;"/>
    </div>
    <div>
      <img class="rounded" @click="toggler = !toggler" :src="resources[1]" alt="" style="width:100%; height:300px;"/>
    </div>
    <FsLightbox
      :toggler="toggler"
      :sources="this.resources"
      type="image"
    />
  </div>
</template>

<script>
import FsLightbox from "fslightbox-vue";
import gmaps from '@/helpers/gmaps'

export default {
  components: { FsLightbox },
  props: {
    latitude: {
      type: Number,
      required: true,
    },
    longitude: {
      type: Number,
      required: true,
    },
    foto: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      toggler: false,
      resources: [],
    };
  },
  async created(){
    this.resources = [
      this.foto,
      'https://maps.googleapis.com/maps/api/staticmap?size=500x400&markers='+this.latitude+','+this.longitude+'&key=AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4&scale=1',
    ]

    // const google = await gmaps();
    // this.$log.info('cargamos gmaps');

    // this.map = new google.maps.Map(document.getElementById('map'), {
    //   center: {
    //     lat: parseFloat(this.latitude),
    //     lng: parseFloat(this.longitude)
    //   }
    //   ,zoom: 17
    // })

    // let marker = new google.maps.Marker({
    //   position: new google.maps.LatLng(parseFloat(this.latitude), parseFloat(this.longitude)),
    //   map: this.map,
    //   title: 'Garantia'
    // });

  },

  methods: {
  },

};
</script>