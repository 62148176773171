import axios from '@/plugins/axios'

const baseURL = process.env.VUE_APP_SERVICIOS_API;
const api_token = process.env.VUE_APP_SERVICIOS_TOKEN

const http = axios.create({ baseURL })

http.interceptors.request.use(config => Object.assign(config, { url: `${config.url}/${api_token}`}))

const headers  = {
    'Accept' : 'application/json',
    'Content-Type': 'application/json'
};

class servicios
{
	/**
     * obtiene la facha de la garantia.
     * @returns {Promise}
     */
    getImagen (id) {
        return http.get(`/documento/${id}`)
    }

    /**
     * Obtiene una url de la foto del modelo de imagenes
     * @param String keyImg
     * @returns {Promise}
     */
    getPhoto (keyImg) {
        keyImg = keyImg.toString();
        var uri = encodeURI(`modelo-imagenes/photo/${api_token}?keyImg='${keyImg}'`)
        return axios.create({ baseURL }).get(uri, { })
    }

    /**
     * Obtiene una lista de estados de la republica
     * @returns {Promise}
     */
     get_estados () {
        return http.get('/entidades')
    }
}

export default new servicios();