import axios from '@/plugins/axios'

const baseURL = process.env.VUE_APP_I360_API;
const api_token = process.env.VUE_APP_I360_TOKEN

const headers  = {
    'authorization': api_token,
    'Accept' : 'application/json',
    'Content-Type': 'application/json'
};

const http = axios.create({ baseURL,
                            headers: headers
                        });
                        
http.interceptors.response.use(({data}) => data)

class ai360
{
	/**
     * Obtención de info expediente
     * @returns {Promise}
     */
    getExpediente (id) {
        return http.get(`/record/${id}`)
    }

    /**
     * Obtención de fotos
     * @returns {Promise}
     */
    getFotos (external_id, external_property_id) {
        return http.get(`/record/${external_id}/photos/${external_property_id}`)
    }
}

export default new ai360();