<template>
  <div id="detalle-modelo-imagenes">
    <div class="container">
      <h2 class="text-center d-block">Modelo Imagenes</h2>
      <h5 class="text-center d-block">Solicitud: {{ solicitud.ServiciosOriginacion.id }}</h5>
      <h6 class="text-center d-block">Tipo Solicitud: {{ solicitud.tipo_solicitud }}</h6>
      <div>
        <div class="card-header" id="headingOne" v-if="!status">
          <span><small>fotografías del modelo de imágenes no disponibles</small></span>
        </div>
        <ul class="nav nav-tabs nav-justified">
          <li class="nav-item" v-for="(garantia, index) of garantias" :key="garantia.id">
            <a class="nav-link" @click.prevent="setActive(index)" :class="{ active: isActive(index) }" href="#">Garantía-{{garantia.id}}</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent" v-for="(garantia, index) of garantias" :key="garantia.id">
          <div class="tab-pane fade" :class="{ 'active show': isActive(index) }" >
            
            <!-- Fotos -->
            <div>
              <ul class="nav nav-tabs nav-justified">
                <li class="nav-item" v-for="(foto, index) of garantia.fotos.payload.property_areas" :key="foto.id">
                    <a v-if="foto.photos.length > 0" class="nav-link" @click.prevent="setActiveModelo(index)" :class="{ active: isActiveModelo(index) }" href="#">{{foto.name}}</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent" v-for="(foto, index) of garantia.fotos.payload.property_areas" :key="foto.id">

                <div v-if="foto.photos.length > 0" class="tab-pane fade" :class="{ 'active show': isActiveModelo(index) }" >
                  
                  <div class="card-header" id="headingOne">
                    <span><small>Comentarios:</small></span>
                    <h4>{{ foto.comment }}</h4>
                  </div>

                  <div class="container">
                    <div class="row">
                      <div class="col-md-6 div-photo" v-if="foto.photos[0]">
                        <fotoMapa :latitude="foto.photos[0].latitude" :longitude="foto.photos[0].longitude" :foto="foto.photos[0].url" />
                      </div>
                      <div class="col-md-6 div-photo" v-if="foto.photos[1]">
                        <fotoMapa :latitude="foto.photos[1].latitude" :longitude="foto.photos[1].longitude" :foto="foto.photos[1].url" />
                      </div>
                    </div>
                  </div>

                  <div class="container">
                    <div class="row">
                      <div class="col-md-6 div-photo" v-if="foto.photos[2]">
                        <fotoMapa :latitude="foto.photos[2].latitude" :longitude="foto.photos[2].longitude" :foto="foto.photos[2].url"/>
                      </div>
                      <div class="col-md-6 div-photo" v-if="foto.photos[3]">
                        <fotoMapa :latitude="foto.photos[3].latitude" :longitude="foto.photos[3].longitude" :foto="foto.photos[3].url"/>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- Fin Fotos -->

          </div>
        </div>
      </div>

      

    </div>
    
    <!-- <div id="modalImages"> -->
      <FsLightbox :toggler="toggler" :sources='imagenes' :key="productIndex" type="image"/>
    <!-- </div> -->
    <div v-if="cesion_creditos">
      <VotacionCesionCredito/>
    </div>
    <div v-else>
      <Votacion/>
    </div>
  </div>
</template>

<script>
  import gmaps from '@/helpers/gmaps'
  import Votacion from '@/apps/comite/pages/Votacion'
  import imagenesGarantia from '@/apps/comite/pages/imagenes-garantia'
  import ai360 from '@/apps/comite/api/ai360'
  import servicios from '@/apps/comite/api/servicios'
  import FsLightbox from "fslightbox-vue";
  import fotoMapa from '@/apps/comite/pages/fotoMapa'
  import VotacionCesionCredito from '@/apps/comite/pages/VotacionCesionCreditos'
export default {
  name: 'modelo-imagenes',
  components: {
    Votacion,
    FsLightbox,
    fotoMapa,
    VotacionCesionCredito,
  },

  created: async function() {
    document.querySelectorAll('.loading')[0].style.display = 'block';
    this.solicitud = this.$store.state.solicitud;
    
    var response_fotos = {};
    var garantias = [];
    var imagenes = [];

    for (const index in this.solicitud.garantias) {
      //17668
      response_fotos = await ai360.getFotos(this.solicitud.solicitud.id, this.solicitud.garantias[index].id)
      .then(result => {
        if(result.status == 200){ 
          garantias.push({ id: this.solicitud.garantias[index].id, fotos: result})
          this.status = true;
        }
      })
      .catch(error => this.$log.info('error',error));
      
    };
    for (const index_garantia in garantias) {
      for (const index_foto in garantias[index_garantia].fotos.payload.property_areas) {
        for (const index in garantias[index_garantia].fotos.payload.property_areas[index_foto].photos) {
          
          await servicios.getPhoto(garantias[index_garantia].fotos.payload.property_areas[index_foto].photos[index].bucket_url)
          .then(result => {
            if(result.status == 200){
              garantias[index_garantia].fotos.payload.property_areas[index_foto].photos[index].url = result.data.url;
              imagenes.push(result.data.url)
            }
          })
          .catch(error => this.$log.info('error', error))
          
        }
      }
    }

    this.garantias = garantias;
    this.imagenes = imagenes;
    document.querySelectorAll('.loading')[0].style.display = 'none';
  },

  data: () => ({
    toggler: false,
    productIndex: 0,
    activeItem: 0,
    activeItemModelo: 0,
    imagenes: [],
    garantias: {},
    expediente: {},
    status: false,
    cesion_creditos: false,
  }),

  async mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });

    if(this.$auth.getUser().roles.comite_ver_creditos_cedidos){
      this.cesion_creditos = true
    }
  },

  computed: {
    
  },

  methods: {
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    isActiveModelo (menuItem) {
      return this.activeItemModelo === menuItem
    },
    setActiveModelo (menuItem) {
      this.activeItemModelo = menuItem
    },
  },
}

</script>

<style lang="scss">
.div-photo {
  padding: 1%;
}
.nav-item .nav-link.active,
.show>.nav-item .nav-link{
    background: #1561d3 !important;
    color: white;
}
  #detalle-modelo-imagenes {
    background-color: $white;
    // min-height: 100vh; 
    margin-bottom: 100px;
    
    .content-tabla {
      color: $text-primary;
      padding-right: 0;
      
      .generales-data {
        display: flex;
        align-items: center;
        span{
          color: $text-primary;
          line-height: 1.5;
          &.text-right{
            text-transform: capitalize;
          }
        }
        
        .heanding-mobile {
          padding-left: 0;
        }
        .text-right {
          font-weight: 700;
          padding-right: 0;
        }
      }
    }
    
    @include mq-min(tablet){
      min-height: 49vh; 
    }
    
    .modelo-imagen {
      padding: 2rem 0;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
 h2 {
    color: $color-blue;
    padding: 1rem;
  }
</style>